@import 'menu-button';

@mixin search-bar($height) {

  position: absolute;
  width: 100%;
  height: $height;
  z-index: 2;

  @include respond-to('mobile, tablet') {
    box-shadow: 0 -3em 1em 3em grey;
  }

  .my-displays-count {
    font-family: $font-stack-bold;
    color: $v1-cco-bright-aqua;
    background-color: white;
    border-radius: 50%;
    width: 1.9em;
    height: 1.9em;
    line-height: 1.9em;
    margin-left: 0.5em;
    display: inline-block;
    vertical-align: text-bottom;
  }

  .menu-button {
    @include menu-button($menu-button-width, $nav-bar-height);
  }

  .controls-container {
    @include position(absolute, null 0 null 0);
    height: $height;
    box-shadow: 3.5em -3em 1em 3em grey;
    z-index: 2;

    @include respond-to('mobile, tablet') {
      left: $menu-button-width;
      right: 0;
      box-shadow: none;
    }

    .geo-autocomplete {
      position: absolute;

      left: 0;
      right: $my-displays-btn-width;
      height: 100%;

      input {
        font-size: 1.2em;
        padding-left: 2rem;

        @include respond-to('mobile, tablet') {
          font-size: 0.9em;
          padding-left: 1rem;
        }
      }
      box-sizing: border-box;

      @include respond-to('mobile, tablet') {
        right: 0;
      }

      div.loading {
        padding-left: 2rem;
      }

      div.error {
        display: none;
      }

      .cco-icon-current-location {
        font-size: 1.5em;

        &::before {
          vertical-align: middle;
        }
      }

      ul.suggestions a{
        padding-left: 2rem;
      }
    }

    .btn.my-displays {
      box-sizing: border-box;
      float: right;
      color: white;
      font-size: 0.8em;
      width: $my-displays-btn-width;
      height: 100%;
      line-height: $height;
      padding: 0.4em;
      text-decoration: none;
      text-transform: uppercase;
      text-align: center;
      background-color: $v1-cco-bright-aqua;

      @include respond-to('mobile, tablet') {
        display: none;
      }

      .my-displays-count {
        display: block;
        margin: 0 auto;
      }

      .text {
        display: block;
        margin: 0.4rem auto;
        line-height: normal;

        &.vert-center {
          position: relative;
          top: 50%;
          margin: 0;
          @include transform(translateY(-50%));
        }
      }

      &:hover {
        $bg-color: $v1-cco-mid-grey;
        background-color: $bg-color;

        .my-displays-count { color: $bg-color; }
      }

      &:active {
        $bg-color: $cco-dark-aqua;
        background-color: $bg-color;

        .my-displays-count { color: $bg-color; }
      }

    }
  }
}
