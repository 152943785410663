.prompt.home-screen {
  strong {
    font-size: 1rem;
    letter-spacing: 0;
  }

  span.cco-icon-apple-share {
    font-size: 1.3rem;
  }

  @include position(absolute, null 0 0 0);
  padding: 0.5rem;
  background-color: $v1-cco-mid-grey;
  text-align: center;
  color: $cco-pale-grey;
  font-size: 1rem;
  z-index: 2;
}
