$marker-width: 32px;
$marker-height: 32px;

@mixin sprite-position($col-index, $row-index) {
  background-position: (-$col-index * $marker-width) (-$row-index * $marker-height);
}

.display-marker {
  background: url('/assets/images/booking-marker-sprite.png');
  background-repeat: no-repeat;
  width: $marker-width !important;
  height: $marker-height !important;

  &.facing-w {
    @include sprite-position(0, 0);
  }

  &.facing-e {
    @include sprite-position(0, 1);
  }

  &.facing-n {
    @include sprite-position(0, 2);
  }

  &.facing-s {
    @include sprite-position(0, 3);
  }

  &.facing-sw {
    @include sprite-position(1, 0);
  }

  &.facing-se {
    @include sprite-position(1, 1);
  }

  &.facing-ne {
    @include sprite-position(1, 2);
  }

  &.facing-nw {
    @include sprite-position(1, 3);
  }

  &.no-facing {
    @include sprite-position(2, 0);
  }
}

.display-marker
.display-tooltip {
  margin-left: 2rem;
  margin-top: 0.3rem;
}

.display-marker:hover
.display-tooltip {
  display: block;
}
