@mixin add-cart-btn($btn-height) {
  $transition-time: 0.1s;

  @include user-select(none);
  @include transition(width $transition-time ease-in-out);

  appearance: none;
  outline-style: none;
  background: none;
  height: $btn-height;
  line-height: $btn-height;
  width: $btn-height;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.5 * $btn-height;
  padding: 0;

  color: $v1-cco-bright-aqua;
  display: inline-block;
  border: 2px solid $v1-cco-bright-aqua;

  .cco-icon-times {
    vertical-align: middle;
    margin-top: -($btn-height / 4.8);
    font-size: 0.5 * $btn-height;
    display: inline-block;
    @include transition(transform $transition-time ease-in-out);
  }

  .text {
    margin-top: -($btn-height / 4.8);
    display: none;
    font-size: 0.6 * $btn-height;
    font-family: sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    vertical-align: middle;
  }

  &:hover {
    border-color: $cco-dark-aqua;
    color: $cco-dark-aqua;
  }

  &.expand-on-hover:hover {
    padding: 0 0.35rem;

    .text {
      display: inline-block;
      margin-left: 0.3rem;
    }
  }

  &:active {
    border-color: $v1-cco-mid-grey;
    color: $v1-cco-mid-grey;
  }

  &.add {
    .cco-icon-times {
      @include transform(rotate(45deg));
    }

    &.expand-on-hover:hover {
      width: 6em;

      .text::after {
        content: 'Add';
      }
    }
  }

  &.remove.expand-on-hover {
    width: 8em;
    padding: 0 0.35em;

    .text {
      margin-left: 0.3em;
      display: inline-block;

      &::after {
        content: 'Remove';
      }
    }
  }

  &.remove {
    .cco-icon-times {
      @include transform(rotate(0deg));
      font-size: 0.6 * $btn-height;
    }
  }
}
