.geo-autocomplete {
  box-sizing: border-box;

  .input-container {
    position: relative;
    background-color: white;
    width: 100%;
    height: 100%;

    .input-wrapper {
      @include position(absolute, 0 5.1rem 0 0);

      input {
        width: 100%;
        height: 100%;
        border-radius: 0;
        letter-spacing: 0.05em;
        color: $v1-cco-mid-grey;
        box-sizing: border-box;
        border: none;
        background-color: transparent;
        font-style: italic;
        text-overflow: ellipsis;

        @include placeholder {
          font-style: normal;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .geo-type {
      font-family: $font-stack-bold;
      color: $v1-cco-bright-aqua;
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
      right: 3.5em;
      font-size: 0.8em;
      letter-spacing: 0.025em;
    }

    .current-location-link {
      position: absolute;
      width: 3rem;
      height: 100%;
      top: 0;
      right: 0;
      text-align: center;
      cursor: pointer;

      @include respond-to(desktop) {
        &:hover .icon-container {
          color: $cco-dark-aqua;
        }
      }

      .icon-container {
        position: absolute;
        top: 50%;
        right: 50%;
        @include transform(translate(50%, -50%));
        color: $v1-cco-bright-aqua;

        &.spin {
          @include animation(rotate 2s infinite linear);
        }
      }

      @include keyframes(rotate) {
        from {
          @include transform(translate(50%, -50%) rotate(0deg));
        }
        to {
          @include transform(translate(50%, -50%) rotate(360deg));
        }
      }
    }
  }

  div.alert.error {
    background-color: rgba(white, 0.8);
    width: 100%;
    position: absolute;
    box-sizing: border-box;
    padding: {
      top: 0.7rem;
      right: 0.5rem;
      bottom: 0.5rem;
      left: 0.5rem;
    }

    &::before {
      display: none;
    }

    h2 {
      &::before {
        @include respond-to('mobile, tablet') {
          top: 0.1rem;
        }
      }

      text-align: left;
      text-transform: none;
      font-size: 1.1rem;
    }

    p {
      color: lighten($v1-cco-dark-grey, 25%);
      text-align: left;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  div.loading {
    text-align: left;
    background-color: rgba(white, 0.8);
    height: 5rem;
    line-height: 5rem;
    width: 100%;
    position: absolute;
    box-sizing: border-box;
    color: $v1-cco-bright-aqua;
    text-transform: uppercase;
    font-size: 1.3rem;
    letter-spacing: 0.1em;

    img, span {
      vertical-align: middle;
    }

    img {
      margin-top: -0.2rem;
    }

    span {
      margin-left: 0.5rem;
    }
  }

  ul.suggestions {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    background-color: rgba(white, 0.8);
    margin: 0;
    padding: 1em 0 0;

    li {
      display: block;
      height: 3em;
      width: 100%;
      font-size: 1em;
      text-align: left;
      list-style: none;

      a {
        display: block;
        line-height: 3em;
        height: 3em;
        width: 100%;
        box-sizing: border-box;
        text-decoration: none;
        color: $v1-cco-mid-grey;
        padding: 0 1em;
        white-space: nowrap;

        .name {
          @include span-columns(9);
          overflow: hidden;
        }

        .type {
          @include span-columns(3);
          overflow: hidden;
        }

        &.highlighted {
          background-color: white;
        }

        &:hover {
          background-color: $cco-dark-aqua;
          color: white;
        }

        &:active {
          background-color: $cco-dark-aqua;
          color: white;
        }
      }
    }
  }
}

.geo-autocomplete.auto-populated
.input-container
input {
  font-style: normal;
}
