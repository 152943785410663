.bg-image-slideshow > div {
  z-index: -99;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include transition(all 4.0s ease-in-out);
  @include filter(blur(1px));
}
