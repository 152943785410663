html
body {
  font-family: $font-stack;
  margin: 0;
}

body > .ember-view,
body > .ember-view > .container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

a, button, label {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

input::-ms-clear {
  display: none;
}

.liquid-container.fill-screen {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  overflow: visible;
}

.liquid-child {
  height: 100%;
  overflow: visible;
}

.icon {
  color: $v1-cco-bright-aqua;
  font-size: 1em;
  padding-top: 0.1em;
}

label {
  font-size: 0.9em;
  color: $cco-dark-aqua;
  display: inline-block;
  font-family: $font-stack-bold;
  text-transform: uppercase;
}

button {
  @include appearance(none);
  background: none;
  border: none;
  outline: none;
}

button.compare {
  float: right;
  border: none;
  background-color: transparent;
  outline-style: none;
  padding: 0;
  cursor: pointer;
}

.hidden {
  display: none;
}

.no-pointer {
  pointer-events: none;
}

h1, h2, h3, h4 {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}
