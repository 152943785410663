$top-bar-height: 0.8rem;
$top-margin: 0.7rem;
$left-text-margin: 2rem;

div.alert {
  &::before {
    content: '';
    display: block;
    @include position(relative, 0 0 null 0);
    height: $top-bar-height;
  }

  h2 {
    position: relative;

    &::before {
      font-family: 'cco-icons';
      font-size: 1.3rem;
      line-height: 1.2rem;
      display: inline;
      @include position(absolute, null null 0 -1.7rem);

      @include respond-to('mobile, tablet') {
        font-size: 1.2rem;
        line-height: 1.2rem;
        left: -1.7rem;
      }
    }

    font-size: 1rem;

    @include respond-to('mobile, tablet') {
      font-size: 0.9rem;
    }

    margin: {
      top: $top-margin;
      left: $left-text-margin;
      bottom: 0.5rem;
    }
    text-transform: uppercase;

    strong {
      font-family: $font-stack-bold;
    }
  }

  div {
    margin-left: $left-text-margin;

    p {
      color: $v1-cco-dark-grey;
      font-size: 0.9rem;
      -webkit-margin-before: 0;
      -webkit-margin-after: 0;
      margin: {
        top: 0.2rem;
        bottom: 0.2rem;
      }
    }
  }
}

div.alert.info {
  &::before {
    background-color: $green;
  }

  h2 {
    &::before {
      content: '\e901';
    }

    color: $green;
  }

  a {
    color: $green;
  }
}

div.alert.error {
  &::before {
    background-color: $error-red;
  }

  h2 {
    &::before {
      content: '\e902';
    }

    color: $error-red;
  }

  a {
    color: $error-red;
  }
}
