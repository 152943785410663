.display-tooltip {
  display: none;
  padding: 0.1rem 0.6rem;
  border-radius: 0 0.8rem 0.8rem 0;
  background-color: $v1-cco-bright-aqua;
  color: white;
  font-size: 1rem;
  letter-spacing: 0.03em;
  white-space: nowrap;
  width: 5.5rem;
}
