@mixin bg-image($imageNum) {
  @include respond-to(mobile) {
    background-image: url('images/landing-bg/' + $imageNum + '/800.jpg');
  }
  @include respond-to(tablet) {
    background-image: url('images/landing-bg/' + $imageNum + '/1024.jpg');
  }
  @include respond-to(desktop) {
    background-image: url('images/landing-bg/' + $imageNum + '/1440.jpg');
  }
}
