@import 'neat-breakpoints';
@import 'add-cart-btn';

$max-width: 60rem;

div.photosheet {
  pointer-events: auto;
  height: 100%;
  max-width: $max-width;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;

  @include position(absolute, 0 0 0 $displays-list-width);
  @include respond-to('mobile, tablet') {
    left: 0;
  }

  color: $cco-dark-aqua;
  background-color: white;
  box-shadow: 0 0 4em 0.8em rgba(black, 0.3);
  overflow-y: scroll;

  $header-height: 5rem;

  >.header.standalone {
    display: none;
  }

  >.header.embedded {
    width: 100%;
    height: $header-height;
    overflow: hidden;
    padding: 2rem 2rem 2rem 3rem;
    @include respond-to(mobile) {
      padding-left: 2rem;
    }

    .back-button a {
      float: left;
      overflow: hidden;
      font-size: 2.5em;
      color: $v1-cco-bright-aqua;
      text-decoration: none;

      &:hover {
        color: $cco-dark-aqua;
      }
    }

    button.cart-btn {
      margin-top: 0.3rem;
      @include respond-to('desktop') {
        @include add-cart-btn(1.5rem);
      }
      @include respond-to('mobile, tablet') {
        @include add-cart-btn(2rem);
      }
      float: right;
      overflow: hidden;
    }
  }

  >.title {
    padding: 2rem 2rem 0 3rem;
    @include respond-to(mobile) {
      padding-left: 2rem;
    }
    margin-top: 0.7rem;
    width: 100%;

    .left {
      display: inline-block;
      width: 75%;

      h1 {
        color: $v1-cco-bright-aqua;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-size: 1.2em;
        margin: 0 0 0.1em 0;
      }

      h2 {
        text-transform: uppercase;
        letter-spacing: 0.025em;
        font-size: 1em;
        margin: 0;
      }
    }

    .buttons {
      display: inline-block;
      float: right;

      @include respond-to(mobile) {
        display: block;
        float: none;
        margin-top: 1rem;
      }

      .share-btns {
        text-align: center;

        @include respond-to(mobile) {
          display: inline-block;
        }

        .cco-icon-fb,
        .cco-icon-twitter,
        .cco-icon-mail {
          color: $v1-cco-bright-aqua;
          font-size: 1.6em;
          cursor: pointer;
          display: inline-block;

          &:hover {
            color: $cco-dark-aqua;
          }
        }

        .cco-icon-fb { float: left; }
        .cco-icon-mail { float: right; }
      }
    }
  }

  >.content {
    padding: 0 2rem 2rem 3rem;
    overflow: hidden;

    @include respond-to(mobile) {
      padding-left: 2rem;
    }

    .top {
      width: 100%;
      display: inline-block;
      margin: 2rem 0;

      p {
        font-size: 1em;
        color: $cco-dark-aqua;
        text-align: justify;
      }
    }

    .section-1 {
      @include span-columns(5);

      @include media($mobile) {
        @include span-columns(12);
      }

      .attrs {
        .field {
          margin-bottom: 0.5rem;

          .icon {
            margin-right: 0.5em;
          }
          label {
            margin-right: 0.3em;
          }
          .value {
            cursor: default;
            letter-spacing: 0.025em;
            color: $cco-dark-aqua;
            display: inline-block;
          }
        }
      }

      .impressions {
        display: none; // temporary until we have impressions data
        margin-top: 2rem;
        width: 100%;

        .header {
          text-align: center;
          font-size: 1.1em;
          color: $v1-cco-bright-aqua;

          &:before, &:after {
            position: relative;
            display: inline-block;
            content: '';
            border-top: 2px solid #33c1d2;
            width: 4.3rem;
            top: -0.1em;
          }

          &:before {
            left: -0.7rem;
          }

          &:after {
            right: -0.7rem;
          }
        }

        .title {
          margin-top: 0.2em;
          font-family: $font-stack-bold;
          letter-spacing: 0.05em;
          width: 100%;
          text-align: center;
          color: $v1-cco-bright-aqua;
          text-transform: uppercase;
        }
      }
    }
    .section-2 {
      @include span-columns(7);

      @include media($mobile) {
        @include span-columns(12);
        margin-top: 3rem;
      }

      img {
        width: 100%;
      }

      .map {
        margin-top: 2rem;
        width: 100%;
        height: 10rem;
      }
    }
  }
}

.photosheet.standalone {
  @include position(absolute, 0 0 0 0);
  margin: 0 auto;
}

.photosheet.standalone {
  .header.embedded {
    display: none;
  }

  .header.standalone {
    display: block;
    margin-top: 2rem;
    padding: 0.8rem 3rem;
    background-color: $v1-cco-bright-aqua;
    height: 3.5rem;

    img {
      width: 18rem;
      max-width: 100%;
    }

    @include respond-to(mobile) {
      padding-left: 2rem;
    }
  }
}
