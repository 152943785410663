@mixin menu-button($width, $height) {
  position: absolute;
  float: left;
  height: $height;
  line-height: $height;
  padding: 0.2em 1.5em;
  background-color: $v1-cco-bright-aqua;
  z-index: 3;

  button {
    position: absolute;
    display: inline-block;
    left: 0;
    top: 0;
    width: $width;
    height: 100%;
    color: white;
    text-decoration: none;

    .my-displays-count {
      position: absolute;
      top: $height / 2;
      left: $width / 2;
      margin-left: 0;
      @include transform(translate(-50%, -50%));
    }

    &:active {
      background-color: $cco-dark-aqua;
    }

    .cco-icon-hamburger {
      position: relative;
      font-size: 2em;
    }
  }
  @include respond-to('desktop') {
    display: none;
  }

  @include respond-to('mobile, tablet') {
    width: $width;
    padding: 0;
    text-align: center;
  }
}
