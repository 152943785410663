@import 'media-sizes';

@mixin respond-to($media) {
  @if str-index($media, mobile) != null {
    @media only screen and (max-width: $tablet-width - 1px) {
      @content;
    }
  }
  @if str-index($media, tablet) != null {
    @media only screen and (min-width: $tablet-width) and (max-width: $desktop-width - 1px) {
      @content;
    }
  }
  @if str-index($media, desktop) != null {
    @media only screen and (min-width: $desktop-width) {
      @content;
    }
  }
}
