@import 'add-cart-btn';

.display-compact {
  display: block;
  height: 9.5rem;
  width: 100%;

  $bar-width: 1rem;

  .ember-view {
    height: 100%;
  }

  .selected-bar {
    display: inline-block;
    height: 100%;
    width: $bar-width;
  }

  &.active .selected-bar {
    background-color: $v1-cco-bright-aqua;
  }

  &:hover:not(.active) .selected-bar {
    background-color: tint($v1-cco-bright-aqua, 65%);
  }

  .container {
    position: absolute;
    left: $bar-width + 1rem;;
    right: 1rem;
    display: inline-block;

    label {
      cursor: pointer;
    }

    .header {
      position: relative;
      display:block;
      width: 100%;
      height: 1.5rem;

      .buttons
      .photosheet-btn {
        display: none;
      }

      @include outer-container;
      .title {
        @include span-columns(9);

        h1 {
          display: none;
        }

        h2 {
          color: $cco-dark-aqua;
          font-size: 0.9em;
          font-family: $font-stack-bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-transform: uppercase;
        }
      }

      .buttons {
        @include span-columns(3);

        .cart-remove {
          display: none;
        }
      }
    }

    .row {
      @include outer-container;
      margin-top: 0.7em;

      .photo {
        @include span-columns(6);
        width: 11em;

        @include respond-to(mobile) {
          width: 7.5em;
        }
      }

      .map {
        display: none;
      }

      .field-details {
        font-size: 1em;
        line-height: 1.13em;
        @include respond-to(mobile) {
          font-size: 0.8em;
          line-height: 1.4em;
        }
        @include span-columns(6);
        .field {
          .icon {
            font-size: 0.8em;
            margin-right: 0.3em;
            display: inline-block;
            @include respond-to(mobile) {
              display: none;
            }
          }
          label {
            margin-right: 0.1em;
            display: inline-block;

            &:after {
              content: ':';
            }
          }
          .value {
            color: $cco-dark-aqua;
            display: inline-block;
          }
        }
      }
    }

    button.cart-toggle {
      @include respond-to('desktop') {
        @include add-cart-btn(1.2rem);
      }
      @include respond-to('mobile, tablet') {
        @include add-cart-btn(1.8rem);
      }
      position: absolute;
      right: 0;
    }
  }
}
