.container .menu {
  @include position(absolute, 0 0 0 0);
  background-color: white;

  .logo-container {
    width: 100%;
    padding: .2em 1.5em;
    background-color: $v1-cco-bright-aqua;
    line-height: $nav-bar-height;

    img {
      width: 15rem;
    }
  }

  .my-displays-count {
    @include transform(translateY(12%));
    text-align: center;
    font-family: $font-stack-bold;
    color: white;
    background-color: $v1-cco-bright-aqua;
    border-radius: 50%;
    width: 1.9em;
    height: 1.9em;
    line-height: 1.9em;
    margin-left: 1.5em;
    display: inline-block;
    vertical-align: text-bottom;
  }

  ul {
    margin-top: 3rem;

    li {
      list-style: none;
      height: 2.5rem;
      line-height: 2.5rem;

      a {
        height: 100%;
        display: inline-block;
        color: $v1-cco-bright-aqua;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        text-decoration: none;
      }

      a.active {
        font-family: $font-stack-bold;
      }
    }
  }

}
