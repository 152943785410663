@mixin filter-bar($height, $top) {
  @include position(relative, $top 0 null 0);
  background-color: white;
  height: $height;


  .btn.add-filters {
    position: relative;
    font-family: $font-stack;
    font-size: 0.8em;
    letter-spacing: 0.1em;
    border: none;
    display: inline-block;
    color: white;
    background-color: $medium-light-gray;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    width: 14rem;
    height: 100%;
    cursor: pointer;
    outline: 0;
    line-height: 100%;
    padding-left: 2rem;

    @include respond-to("mobile, tablet") {
      width: 100%;
    }

    &:hover {
      background-color: $v1-cco-mid-grey;
    }

    &:active {
      background-color: $cco-dark-aqua;
    }

    &::after {
      font-family: 'cco-icons';
      position: absolute;
      content: "\e600";
      right: 1rem;
      display: inline-block;
      @include transform(rotate(90deg));
    }
  }
}
