@import 'product-category-filter';
@import 'displays-filter';

$padding: 2rem;
$title-height: 5rem;
$footer-height: 4rem;

.filters-container {
  @include position(absolute, 3.5rem 0 0 0);
  padding: $padding 0 0 $padding;
  background-color: white;
  color: $v1-cco-mid-grey;
  overflow: hidden;

  label {
    font-size: 1rem;
    color: $v1-cco-mid-grey;
    display: inline-block;
    font-family: $font-stack;
    text-transform: uppercase;
  }

  .loading {
    margin: 2em 0;

    img {
      width: 1.5em;
    }

    span {
      display: inline-block;
      margin: -1rem 0 0 0.5rem;
      letter-spacing: 0.06rem;
      text-transform: uppercase;
      color: $v1-cco-mid-grey;
      vertical-align: middle;
    }
  }

  h1, h2 {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: $v1-cco-bright-aqua;
  }

  h1 {
    font-size: 1.8em;
  }

  h2 {
    font-size: 1.3em;
  }

  fieldset {
    margin-top: 2rem;
    padding: 0;
    margin: 0;
    -webkit-padding-start: 0;
    -webkit-padding-end: 0;

    legend {
      text-transform: uppercase;
      overflow: hidden;
      font-size: 1em;
      letter-spacing: 0.05em;
      color: $v1-cco-bright-aqua;
      -webkit-padding-start: 0;
      -webkit-padding-end: 0;
    }
  }

  fieldset.media-format {
    .controls {
      @include respond-to('desktop') {
        width: 60%;
        @media (max-width: 1200px) {
          width: 70%;
        }
      }

      .radio {
        display: inline-block;

        @include respond-to('mobile, tablet') {
          display: block;
          margin-top: 1.5rem;

          &:first-child {
            margin-top: 0.5rem;
          }
        }
      }

      @include respond-to('desktop') {
        text-align: center;

        .radio.digital-static {
          float: left;
        }

        .radio.static {
          float: right;
        }
      }
    }

  }

  fieldset.search-radius {
    margin-top: 3rem;
    padding-bottom: 2rem;

    .controls {
      margin-top: 2rem;
      height: 1rem;
      width: 100%;
      display: inline-block;

      .range-slider {
        display: inline-block;
        position: absolute;
        left: $padding;
        right: $padding + 5rem + 1rem;
      }
      span {
        display: inline-block;
        position: absolute;
        height: 1rem;
        line-height: 1rem;
        right: $padding;
        width: 5rem;
        text-align: right;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        color: $v1-cco-bright-aqua;
      }
    }
  }

  section {
    h2 {
      margin-bottom: 1rem;
    }
  }


  .sections {
    @include position(absolute, $title-height 0 $footer-height 0);
    padding: $padding;
    overflow-y: scroll;

    section {
      margin-bottom: $padding * 2;
    }

    section.products .product-categories {
      position: relative;
    }

    section.additional-filters {
      .cb:first-of-type {
        @include respond-to('mobile, tablet') {
          $checkbox-image-border: 0.1rem;
          padding-left: $checkbox-image-border;
        }
      }
    }
  }

  .footer {
    @include position(absolute, null 0 0 0);
    background-color: $medium-light-gray;
    height: $footer-height;
    line-height: $footer-height;
    text-align: center;

    .btn {
      position: relative;
      font-family: $font-stack;
      font-size: 0.8em;
      letter-spacing: 0.1em;
      display: inline-block;
      color: white;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      cursor: pointer;
      outline: 0;
      padding: 0.8em 1em;
      line-height: 100%;

      &.exit, &.apply {
        border: 2px solid white;
        background: transparent;

        &:hover {
          border-color: $cco-dark-aqua;
          color: $cco-dark-aqua;
        }

        &:active {
          border-color: $v1-cco-mid-grey;
          color: $v1-cco-mid-grey;
        }
      }

      &.apply {
        margin-left: 1rem;
      }
    }
  }

  &::after {
    content: "";
    @include position(absolute, -10rem -10rem -10rem 0);
    box-shadow: inset 0 0 4em 0.8em $v1-box-shadow-color;
    pointer-events: none;

    @include respond-to("mobile, tablet") {
      display: none;
    }
  }
}
