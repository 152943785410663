$size: 6.25rem;
$overlay-z-index: 1000;

.map-container
.cluster-fan {
  position: relative;
  width: $size !important;
  height: $size !important;
  margin-top: -$size / 2 !important;
  margin-left: -$size / 2 !important;
  z-index: $overlay-z-index !important;
  background-color: rgba(white, 0.85);
  border-radius: 50%;
  border: 2px solid $v1-cco-bright-aqua;

  .cluster-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
  }

  .display-icon {
    position: absolute;
    $size: 1rem;
    $half: $size / 2;
    $quarter: $half / 2;
    background-color: $v1-cco-bright-aqua;
    width: $size;
    height: $size;
    border-radius: $half;
    z-index: $overlay-z-index + 1;

    &.facing-n {
      width: $size;
      height: $half;
      border-radius: 0 0 $half $half;
    }

    &.facing-ne {
      @extend .facing-n;
      @include transform(rotate(45deg));
    }

    &.facing-nw {
      @extend .facing-n;
      @include transform(rotate(-45deg));
    }

    &.facing-s {
      width: $size;
      height: $half;
      border-radius: $half $half 0 0;
    }

    &.facing-se {
      @extend .facing-s;
      @include transform(rotate(-45deg));
    }

    &.facing-sw {
      @extend .facing-s;
      @include transform(rotate(45deg));
    }

    &.facing-e {
      width: $half;
      height: $size;
      border-radius: $half 0 0 $half;
    }

    &.facing-w {
      width: $half;
      height: $size;
      border-radius: 0 $half $half 0;
    }
  }

  .display-icon
  .display-tooltip {
    margin-left: 1.5rem;
    margin-top: -0.2rem;
  }

  .display-icon:hover {
    background-color: $cco-dark-aqua;

    .display-tooltip {
      display: block;
    }
  }
}
