.map {
  overflow: hidden;

  .remove-boundary {
    position: absolute;
    top: $nav-bar-height + $filter-bar-height + 1rem;
    right: 2rem;
    padding: 0.8rem;
  }
}

.map,
.map>div,
.map-container, {
  width: 100%;
  height: 100%;
}
