$font-stack-bold: 'Proxima Nova Alt Bold', sans-serif;
$font-stack: 'Proxima Nova Alt Regular', sans-serif;
$cco-logo-small-url: 'images/cco-logo-300.png';
$cco-logo-med-url: 'images/cco-logo-600.png';
$cco-logo-large-url: 'images/cco-logo-1024.png';

// CCO colors:
$cco-dark-aqua: #004651;
$cco-pale-grey: #F2F2F2;
$error-red: #EF3F4A;
$purple: #8E52A1;
$green: #72BF44;
$medium-light-gray: #c2c2c1;

// Misc
$nav-bar-height: 3.5rem;
$filter-bar-height: 2.5rem;
$displays-list-width: 30rem;
$toggle-bar-height: 2.5rem;
$my-displays-btn-width: 10rem;
$menu-button-width: 2.8rem;

// V1 colors
$v1-cco-bright-aqua: #33c1d2;
$v1-cco-mid-grey: #8996A0;
$v1-cco-dark-grey: #32363F;
$v1-dark-navy: #233d64;
$v1-box-shadow-color: rgba(black, 0.3);
