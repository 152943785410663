@import 'display-compact';

.displays-container {
  .logo-container {
    width: 100%;
    padding: .2em 1.5em;
    background-color: $v1-cco-bright-aqua;
    line-height: 3.5rem;

    img {
      width: 15rem;
    }
  }
  .displays {
    .loading {
      margin: {
        left: 2rem;
        top: 2rem;
      }
      line-height: 2.4rem;
      height: 2.4rem;

      img {
        vertical-align: middle;
      }

      >span {
        display: inline-block;
        vertical-align: middle;
        margin-left: 0.5rem;
        letter-spacing: 0.06rem;
        text-transform: uppercase;
        color: $v1-cco-bright-aqua;
        vertical-align: middle;
      }
    }

    .count {
      color: $v1-cco-mid-grey;
      margin: {
        left: 2rem;
        top: 2rem;
      }
      font-size: 0.8em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      line-height: 2.4rem;
      height: 2.4rem;

      @include respond-to(mobile) {
        margin-right: 1rem;
      }
    }

    .no-results {
      @extend .count;
      line-height: 1.5rem;
    }

    .displays-list {
      margin-top: 2rem;
    }

    .display-compact {
      margin-bottom: 3em;
    }

    .loading-more {
      text-align: center;
      padding-bottom: 2rem;
    }

  }
}
