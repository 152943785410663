textarea {
  @include appearance(none);
  border-radius: 0;
  outline: none;
}

fieldset {
  border: none;
}

label {
  letter-spacing: 0.05em;
}

label.cb {
  @include user-select(none);
  cursor: pointer;
  white-space: nowrap;

  .cb-icon {
    display: inline;
    vertical-align: top;

    &::after {
      content: '\e617';
      font-family: 'cco-icons';
      vertical-align: top;
      display: inline;
      position: relative;
      left: 0;
      top: 0;
    }
  }

  .text {
    display: inline-block;
    white-space: normal;
    letter-spacing: 0.05em;
  }

  input[type='checkbox'] {
    display: none;

    &:checked ~ .cb-icon::after {
      content: '\e604';
    }

    &:indeterminate ~ .cb-icon::after {
      content: '\e60e';
    }
  }
}

.radio {
  @include user-select(none);
  cursor: pointer;

  .radio-icon::after {
    content: '\e800';
    font-family: 'cco-icons';
    display: inline-block;
    position: relative;
    left: -2px;
    top: 1px;
  }

  input[type='radio'] {
    display: none;

    &:checked ~ .radio-icon::after {
      content: '\e801';
    }
  }
}
