@font-face {
  font-family: "Proxima Nova Alt Black";
  src: url("/assets/fonts/ProximaNovaA-Black.ttf");
}
@font-face {
  font-family: "Proxima Nova Alt Bold";
  src: url("/assets/fonts/ProximaNovaA-Bold.ttf");
}
@font-face {
  font-family: "Proxima Nova Alt Extrabold";
  src: url("/assets/fonts/ProximaNovaA-Extrabld.ttf");
}
@font-face {
  font-family: "Proxima Nova Alt Light";
  src: url("/assets/fonts/ProximaNovaA-Light.ttf");
}
@font-face {
  font-family: "Proxima Nova Alt Regular";
  src: url("/assets/fonts/ProximaNovaA-Regular.ttf");
}
@font-face {
  font-family: "Proxima Nova Alt Semibold";
  src: url("/assets/fonts/ProximaNovaA-Semibold.ttf");
}
@font-face {
  font-family: "Proxima Nova Alt Thin";
  src: url("/assets/fonts/Proxima Nova Alt-Thin.ttf");
}
@font-face {
  font-family: "Proxima Nova Alt Thin2";
  src: url("/assets/fonts/ProximaNovaAlt-Thin2.ttf");
}