.landing {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
  z-index: 1;
  overflow: hidden;

  .overlay {
    position: absolute;
  	box-shadow: inset 0px 0px 300px rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    background-color: rgba($v1-dark-navy, 0.4);
  }

  .content {
    .content-container {
      position: absolute;
      width: 100%;
      top: 30%;
      left: 50%;
      @include transform(translate(-50%, -50%));
    }

    position: relative;
    top: 30%;
    width: 100%;
    height: 60%;
    text-align: center;

    @include respond-to(mobile) {
      text-align: center;
      top: 25%;
    }

    .logo-container {
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
      width: 32em;

      @include respond-to(mobile) {
        width: 90%;
      }

      $padding: 0.3em;
      $extra: 0.8em;
      padding: $padding + 0.4em ($padding + $extra) $padding ($padding + $extra);
      background-color: rgba($v1-cco-bright-aqua, 0.65);

      img {
        width: 100%;
      }
    }

    .search-section {
      left: 50%;
      z-index: 1;
      width: 55em;
      margin: 4em auto 0 auto;

      @include respond-to(mobile) {
        width: 90%;
        margin: 1em auto 0 auto;
      }
      @include respond-to(tablet) {
        width: 90%;
      }

      .search-panel {
        box-sizing: border-box;
        $padding: 1.2em;
        padding: $padding 0 $padding 0;
        text-align: center;

        .heading {
          display: inline-block;

          $padding: 0.8em;
          $extra: 0.8em;

          @include respond-to(mobile) {
            $padding: 0.4em;
            $extra: 0.2em;
          }
          @include respond-to(tablet) {
            $padding: 0.4em;
            $extra: 0.2em;
          }
          padding: $padding ($padding + $extra) $padding ($padding + $extra);

          h3 {
            text-transform: uppercase;
            margin: 0;
            font-family: $font-stack;
            letter-spacing: 0.2em;
            z-index: 1;
            color: white;
            font-size: 1.5em;
            @include respond-to(mobile) {
              font-size: 1em;
            }
            @include respond-to(tablet) {
              font-size: 1.5em;
            }
          }
        }

        .geo-autocomplete {
          position: relative;
          box-sizing: border-box;
          margin-top: 0.8em;
          margin-left: auto;
          margin-right: auto;
          width: 32em;
          height: 3.64rem;

          @include respond-to(mobile) {
            width: 100%;
          }

          .input-container {
            position: relative;
            background-color: white;

            @include respond-to(mobile) {
              height: 2.5rem;
            }

            input {
              padding: 0 0 0 1em;
              font-size: 1.3em;

              @include respond-to(mobile) {
                font-size: 1em;
                height: 2.5rem;
              }
              @include respond-to(tablet) {
                font-size: 1.3em;
              }
            }

            .cco-icon-current-location {
              font-size: 1.5em;

              &::before {
                vertical-align: middle;
              }
            }
          }

          div.loading {
            padding-left: 1rem;

            @include respond-to(mobile) {
              height: 4rem;
              line-height: 4rem;

              img {
                width: 1.5rem;
              }

              span {
                margin-left: 0.2rem;
                font-size: 1rem;
              }
            }
          }

          ul.suggestions {
            li {
              @include respond-to(mobile) {
                font-size: 0.8em;
              }
            }
          }
        }

        .search {
          display: inline-block;
          margin-top: 2em;
          margin-left: auto;
          margin-right: auto;
          font-size: 1.5em;
          text-decoration: none;
          text-transform: uppercase;

          @include respond-to(mobile) {
            font-size: 1.2em;
          }
        }
      }

    }
  }

  .btn {
    display: block;
    background-color: transparent;
    color: white;
    box-sizing: border-box;
    z-index: 0;
    outline: 0;
    $padding: 0.2em;
    $extra: 0.2em;
    padding: $padding ($padding + $extra) $padding ($padding + $extra);
    border: 2px solid white;
    letter-spacing: 0.05em;

    &:hover {
      cursor: pointer;
      color: $v1-cco-bright-aqua;
      border-color: $v1-cco-bright-aqua;
    }

    &:active {
      cursor: pointer;
      color: $cco-dark-aqua;
      border-color: $cco-dark-aqua;
    }
  }

  .bg-image-1 {
    @include bg-image(1);
  }

  .bg-image-2 {
    @include bg-image(2);
  }

  .bg-image-3 {
    @include bg-image(3);
  }

  .bg-image-4 {
    @include bg-image(4);
  }

  .bg-image-5 {
    @include bg-image(5);
  }
}
