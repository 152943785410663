@import 'add-cart-btn';
@import 'display-row';
@import 'menu-button';

div.my-displays {

  .menu-button {
    @include menu-button($menu-button-width, $nav-bar-height);
    z-index: 1;
  }

  .btn.results {
    @include position(absolute, 0 0 null null);
    z-index: 1;
    box-sizing: border-box;
    color: white;
    font-size: 0.8em;
    width: $my-displays-btn-width;
    height: $nav-bar-height;
    line-height: $nav-bar-height;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    background-color: $v1-cco-bright-aqua;

    &:hover {
      background-color: $v1-cco-mid-grey;
    }

    &:active {
      background-color: $cco-dark-aqua;
    }

    @include respond-to('mobile, tablet') {
      display: none;
    }
  }

  .map-container, .map-canvas {
    position: relative;
    width: 100%;
    height: 20rem;

    @include respond-to('mobile, tablet') {
      height: 16rem;
    }
  }

  .displays > .header.main {
    overflow: visible;

    .title {
      width: 50%;

      @include respond-to('mobile, tablet') {
        height: 2.8rem;
        line-height: 2.8rem;
        width: 100%;
      }
    }

    .buttons {
      width: 50%;

      @include respond-to('mobile, tablet') {
        text-align: left;
        height: 2.8rem;
        line-height: 2.8rem;
        width: 100%;

        .photosheet-btn {
          display: inline-block;

          ul {
            left: 0;
            right: auto;
            text-align: left;
          }
        }
      }

      .all-photosheets {
        margin-right: 1rem;
      }

      .copy-to-clipboard {
        display: inline-block;
        margin-right: 1rem;

        button {
          width: 8rem;
        }

        @include respond-to('mobile') {
          display: none;
        }
      }
    }
  }

  .displays {
    padding: 2em;

    $header-height: 5rem;

    .header {
      width: 100%;
      height: $header-height;
      line-height: $header-height;

      >div {
        display: block;
        float: left;
        height: $header-height;
        line-height: $header-height;
        vertical-align: middle;
      }

      .title {
        width: 50%;
        color: $cco-dark-aqua;

        h1 {
          text-transform: uppercase;
          color: $v1-cco-bright-aqua;
          font-weight: bold;
        }

        .count-circle {
          width: 1.5rem;
          height: 1.5rem;
          line-height: 1.5rem;
          padding: 0;
          color: white;
          background-color: $v1-cco-bright-aqua;
          text-align: center;
          font-weight: bold;
          border-radius: 50%;
          font-size: 0.8rem;
          display: inline-block;
          float: none;
          margin: -0.25rem 0 0 0.5rem;

          @include respond-to(desktop) {
            width: 2.5rem;
            height: 2.5rem;
            line-height: 2.5rem;
            font-size: 1.5rem;
            margin: -0.7rem 0 0 1rem;
          }
        }
      }


      .buttons {
        width: 50%;
        text-align: right;

        .photosheet-btn {
          margin-right: 1rem;

          @include respond-to('mobile, tablet') {
            display: none;
          }
        }

        button.cart-remove .cco-icon-times {
          font-size: 0.8em;
          margin-top: -0.25rem;
        }
      }
    }

    >.header h1 {
      display: inline;
    }

    .display-count {
      .label {
        margin-left: 0.5rem;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        color: $v1-cco-bright-aqua;
        font-weight: bold;
        display: inline;
      }
    }

    @include respond-to('mobile, tablet') {
      padding: 1rem;

      >.header .title {
        width: 60%;

        h1 {
          font-size: 1.2rem;
        }
      }

      >.header .links {
        display: none;
      }

      >.header .buttons {
        width: 40%;
      }

      .display-count {
        display: none;
      }
    }
  }

  .display-compact {
    margin-top: 3rem;

    .selected-bar {
      display: none;
    }

    .container {
      left: 1rem;
    }

    .container > .header {
      height: 1.5rem;
      line-height: 1.5rem;
      overflow: visible;
      @include outer-container;

      div {
        height: 1.5rem;
        line-height: 1.5rem;
      }

      .title {
        @include span-columns(9);
      }

      .buttons {
        @include span-columns(3);
      }
    }
  }
}
