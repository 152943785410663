$color: $v1-cco-bright-aqua;
$active-color: $cco-dark-aqua;

.ghost-btn {
  display: inline-block;
  border: 2px solid $color;
  outline: none;
  background: none;
  font-family: $font-stack-bold;
  color: $color;
  text-decoration: none;
  font-size: 0.8em;
  line-height: 0.8em;
  text-transform: uppercase;
  padding: 0.4em;
  cursor: pointer;

  @include respond-to(mobile) {
    display: inline-block;
  }

  &:hover {
    color: $active-color;
    border-color: $active-color;
  }
}

.ghost-btn.disabled {
  $color: $medium-light-gray;
  border: 2px solid $color;
  color: $color;
  cursor: default;
}

.ghost-btn.solid {
  background: $color;
  color: white;
  font-family: $font-stack;
  border: none;

  &:hover {
    background: $cco-dark-aqua;
  }
}

.photosheet-btn {
  display: inline-block;
  position: relative;
  line-height: 1em;

  button:after {
    content: '\25BE';
    position: relative;
    top: -0.1em;
    padding-left: 0.15em;
  }

  ul {
    display: none;
    z-index: 1;

    position: absolute;
    right: 0;
    width: 16em;

    &.with-unique {
      width: 21em;
    }

    list-style: none;
    background-color: #fff;
    font-family: $font-stack-bold;
    font-size: 0.8em;
    line-height: 0.8em;
    text-transform: uppercase;
    text-align: right;

    margin: 0;
    margin-top: -2px;
    padding: 0.5em 0;
    border: 2px solid $active-color;

    @include respond-to(mobile) {
      left: 0;
      right: auto;
      text-align: left;
    }

    li {
      padding: 0;

      &:last-child {
        padding-bottom: 0.2em;
      }
    }

    a {
      color: $active-color;
      display: block;
      padding: 1em;
      text-decoration: none;

      &:hover {
        color: $color;
      }
    }
  }

  &:hover,
  &:focus-within {
    ul {
      display: block;
    }
  }
}
