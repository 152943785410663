@import 'search-bar';
@import 'filter-bar';
@import 'filters';
@import 'displays-list';
@import 'map/index';
@import 'photosheet';

.results {
  position: absolute;
  width: 100%;
  height: 100%;

  >.nav {
    @include position(absolute, 0 0 0 $displays-list-width);
    z-index: 2;
    pointer-events: none;

    .search-bar {
      @include search-bar($nav-bar-height);
      pointer-events: auto;
    }

    .filter-bar {
      @include filter-bar($filter-bar-height, $nav-bar-height);
      pointer-events: auto;
    }

    .filters-container {
      pointer-events: auto;
      -webkit-overflow-scrolling: touch;
    }
  }


  >.list {
    position: absolute;
    margin: 0;
    box-shadow: 0 0 4em 0.8em $v1-box-shadow-color;
    background: white;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    width: $displays-list-width;

    .logo-container {
      position: relative;
      height: $nav-bar-height;
      width: 100%;
      background-color: $v1-cco-bright-aqua;
      padding: 0.2em 1.5em;
    }

    .alert.preview-message {
      margin: 0.1rem 1rem 0 2rem;

      &::before {
        display: none;
      }

      h2 {
        margin-left: 0;

        &::before {
          display: none;
        }
      }

      div {
        margin-left: 0;
      }
    }

    .alert.error {
      margin: 0.1rem 0 0 0;

      h2 {
        text-transform: none;
      }

      div {
        margin-right: 1rem;
      }
    }

    .displays {
      position: absolute;
      top: $nav-bar-height;
      bottom: 0;
      width: 100%;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      margin: 0;
    }
  }

  >.content {
    z-index: 0;
    position: absolute;
    top: $nav-bar-height;
    left: $displays-list-width;
    right: 0;
    bottom: 0;

    &.full-height {
      top: 0;
    }
  }

  >.photosheet-container {
    @include respond-to('mobile, tablet') {
      z-index: 1;
    }
  }

  >.toggle-view-container {
    display: none;
    position: absolute;
    height: $toggle-bar-height;
    bottom: 0;
    left: 0;
    right: 0;

    .btn {
      font-family: $font-stack;
      font-size: 0.8em;
      letter-spacing: 0.1em;
      border: none;
      display: inline-block;
      color: white;
      background-color: $medium-light-gray;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      width: 50%;
      height: 100%;
      cursor: pointer;
      outline: 0;
      line-height: $toggle-bar-height;

      &:hover {
        background-color: $v1-cco-mid-grey;
      }

      &.highlighted {
        background-color: $v1-cco-bright-aqua;
      }
    }

    .btn.show-list {
      float: left;
    }

    .btn.show-map {
      float: right;
    }
  }

  @include respond-to('mobile, tablet') {
    >.nav {
      @include position(absolute, 0 0 0 0);
    }

    >.list {

      .logo-container {
        display: none;
      }

      display: none;
      right: 0;
      width: 100%;
      top: $nav-bar-height;
      bottom: $toggle-bar-height;
      box-shadow: none;

      @include respond-to('mobile, tablet') {
        top: $filter-bar-height;
      }
    }

    >.content {
      visibility: hidden;
      left: 0;
      bottom: $toggle-bar-height;
    }

    >.list.active, .content.active {
      display: block;
      visibility: visible;
    }

    >.toggle-view-container {
      display: block;
    }
  }
}
