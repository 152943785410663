.display-row>.container {
  overflow: hidden;
  margin-top: 3.5rem;

  $header-height: 2rem;
  .header {
    margin-bottom: 1rem;
    height: $header-height;
    line-height: $header-height;

    .title {
      padding-top: 1rem;

      h1 {
        display: block;
        color: $v1-cco-bright-aqua;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-size: 1.2em;
        line-height: 1em;
        height: 1em;
        margin: 0 0 0.4em 0;
      }

      h2 {
        display: block;
        text-transform: uppercase;
        letter-spacing: 0.025em;
        font-size: 1em;
        line-height: 1em;
        height: 1em;
        margin: 0;
      }
    }
  }

  .header > .buttons > .cart-toggle {
    display: none;
  }

  $field-height: 7rem;
  $fixed-field-width: 12rem;
  $num-fixed-fields: 2;
  $num-fluid-fields: 6;

  .row {
    @include position(relative, 0 null null 0);
  }

  .field-details {
    @include position(absolute,
      null 0 null ($num-fixed-fields * $fixed-field-width));
    height: $field-height;
  }

  .row > .field {
    width: $fixed-field-width;
    padding: 0 1rem 0 0;
  }

  .field-details > .field {
    width: percentage(1.0 / $num-fluid-fields);
    border-right: 1px solid $medium-light-gray;

    &:last-child {
      border: none;
    }
  }

  .field {
    overflow: hidden;
    display: block;
    float: left;
    height: $field-height;
    padding: 0.5em 0;
    text-align: center;
  }

  .field .icon {
    font-size: 1.7em;
    padding: 0 0 0.5em 0;
    @include respond-to(mobile) {
      display: none;
    }
  }

  .field label {
    padding: 0 0 0.8em 0;
    @include respond-to(mobile) {
      &::after {
        content: ":";
      }
    }
  }

  .field .value {
    color: $v1-cco-mid-grey;
  }

  .field.photo {
    border: none;

    img.photo {
      width: 100%;
    }
  }

  .field.map {
    @include respond-to('mobile, tablet') {
      display: none;
    }

    .map-container {
      height: $field-height;
      line-height: 1rem;
      width: 100%;

      .map-canvas {
        height: $field-height;
        width: 100%;
      }
    }
  }
}
