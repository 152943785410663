.filters-container
section.displays {
  div.toggle-btns {
    font-size: 0; // removes horizontal spacing from between buttons

    width: 20rem;
    @include respond-to('mobile, tablet') {
      width: 100%;
    }

    button {
      display: inline-block;
      position: relative;
      padding: 0.3rem 0;
      margin: 0;
      cursor: pointer;
      border: 1px solid lighten($v1-cco-mid-grey, 20%);
      text-transform: uppercase;
      font-size: 0.75rem;
      color: $v1-cco-bright-aqua;
      box-sizing: border-box;
      background-color: white;
    }

    button.active {
      background-color: $v1-cco-bright-aqua;
      border-color: $v1-cco-bright-aqua;
      color: white;

      &:after {
        position: absolute;
        top: 100%;
        left: 50%;
        pointer-events: none;
        @include transform(translateX(-50%));
        display: block;
        content: '';
        border: 2rem solid transparent;
        border-top-width: 0.7rem;
        border-top-color: $v1-cco-bright-aqua;
        width: 0;
        height: 0;
      }
    }

    button.tab-ids {
      width: 40%;
    }

    button.display-nums {
      width: 60%;
    }
  }

  div.input-container {
    width: 75%;
    height: 10rem;
    border: 1px solid lighten($v1-cco-mid-grey, 20%);
    display: block;
    margin: 0;
    padding: 1rem 0 0 1rem;

    @include respond-to('mobile, tablet') {
      width: 100%;
    }
  }

  div.input-container textarea {
    font-family: $font-stack;
    font-size: 1rem;
    color: $v1-cco-mid-grey;
    border: none;
    width: 100%;
    height: 100%;
    resize: none;

    @include placeholder {
      text-transform: uppercase;
    }
  }
}
