@import 'forms';


.filters-container
section.products
.product-category-container {
  margin-top: 1.5rem;

  &:first-of-type {
    margin-top: 0.5rem;
  }

  width: 100%;

  $desktop-width: 21rem;
  @include respond-to(desktop) {
    width: $desktop-width;
  }

  fieldset {
    text-transform: uppercase;
    border: none;

    @include respond-to(desktop) {
      &.expanded .header {
        border-bottom: 2px solid tint($v1-cco-bright-aqua, 50%)
      }
    }

    $header-height: 2rem;
    .header {
      width: 100%;
      overflow: visible;
      height: $header-height;
      line-height: $header-height;

      @include respond-to('mobile, tablet') {
        .cb.select-all .text {
          width: 8rem;
        }
      }

      button {
        height: $header-height - 0.1rem; // Slightly reduce to prevent border from overlapping
        float: right;
        overflow: hidden;
        width: 6rem;
        font-size: 0.8rem;
        letter-spacing: 0.1em;
        border: none;
        border-left: 2px solid $cco-pale-grey;
        background: transparent;
        text-align: right;
        outline: 0;
        cursor: pointer;
        text-transform: uppercase;
        color: $v1-cco-bright-aqua;
        padding:0;
        margin: 0;

        @include respond-to('mobile, tablet') {
          padding: 0;
        }

        &.less-btn::after, &.more-btn::after {
          content: '';
          font-family: 'cco-icons';
          position: relative;
          left: 0;
          content: '\e600';
          display: inline-block;
          white-space: nowrap;

          @include respond-to('mobile, tablet') {
            left: -0.2rem;
          }
        }

        &.more-btn::after {
          @include transform(rotate(90deg));
        }

        @include respond-to('mobile, tablet') {
          width: 6rem;
        }
      }

      @include respond-to('mobile, tablet') {
        legend {
          float: none;
          display: block;
        }

        .controls {
          float: none;
          display: block;
        }
      }
    }
  }

  .products {
    padding: 0 0 1rem 1rem;
    overflow: hidden;

    @include respond-to('mobile, tablet') {
      margin-top: 3.5rem;

      @include respond-to('mobile, tablet') {
        margin-top: 2.5rem;
      }
    }

    @include respond-to(desktop) {
      position: absolute;
      left: $desktop-width + 5rem;
      top: 0;
      right: 0;
      max-width: 30rem;
      min-width: 15rem;
    }

    label {
      @include span-columns(6);

      @include media($mobile) {
        @include span-columns(12);
      }

      @include pad(0.5rem 0);
      @include omega(2n);
    }
  }
}
